import axios from '@axios'
export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchData(ctx,queryParams){
            var data = JSON.stringify(queryParams);
              
            return new Promise((resolve, reject) => {
              axios
                .post('/services/v1/getall',data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
          },
          getCourses(ctx,patient_id){
            return new Promise((resolve, reject) => {
              axios
                .get('/receipt/v1/getcourses/'+patient_id)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })

          },
        
          updateReceiptStatusData(ctx,queryParams){
            var data = JSON.stringify(queryParams);
              
            return new Promise((resolve, reject) => {
              axios
                .put('/receipt/v1/update-status/'+queryParams.id,data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
          },
          fetchReceiptData(ctx,queryParams){
            var data = JSON.stringify(queryParams);
              
            return new Promise((resolve, reject) => {
              axios
                .post('/receipt/v1/getall',data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
          },
          fetchReceiptStatusData(ctx,queryParams){
            var data = JSON.stringify(queryParams);
              
            return new Promise((resolve, reject) => {
              axios
                .post('/receipt/status/v1/getall',data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })

          },
          addReceiptStatus(ctx,queryParams){
            var data = JSON.stringify(queryParams);
              
            return new Promise((resolve, reject) => {
              axios
                .post('/receipt/status/v1/add',data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })

          },
          deleteItemReceipt(ctx,id){
           
              
            return new Promise((resolve, reject) => {
              axios
                .delete('/receipt/v1/items/delete/'+id)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })

          },
          updateReceiptItems(ctx,queryParams){
            var data = JSON.stringify(queryParams);
              
            return new Promise((resolve, reject) => {
              axios
                .post('/receipt/v1/items/update',data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
          },
          addReceiptItems(ctx,queryParams){
            var data = JSON.stringify(queryParams);
              
            return new Promise((resolve, reject) => {
              axios
                .post('/receipt/v1/items/add',data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })

          },
          ChangeItemPrice(ctx,queryParams){
            var data = JSON.stringify(queryParams);
              
            return new Promise((resolve, reject) => {
              axios
                .post('/receipt/v1/items/changeprice',data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })

          },
          
          updateReceiptStatus(ctx,queryParams){
            var data = JSON.stringify(queryParams);
              
            return new Promise((resolve, reject) => {
              axios
                .put('/receipt/status/v1/update/'+queryParams.id,data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })

          },
          deleteStatus(ctx,id){
            return new Promise((resolve, reject) => {
              axios
                .delete('/receipt/status/v1/delete/'+id)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
          },
          
          fetchGatewayData(ctx,queryParams){
            var data = JSON.stringify(queryParams);
              
            return new Promise((resolve, reject) => {
              axios
                .post('/gateway/v1/getall',data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
          },
          fetchDataDetail(ctx,id){
            
              
            return new Promise((resolve, reject) => {
              axios
                .get('/receipt/v1/detail/'+id)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
          },
          fetchOpdCardDetail(ctx,id){
            
              
            return new Promise((resolve, reject) => {
              axios
                .get('/opdcard/v1/detail/'+id)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
          },
          fetchaddReceipt(ctx,queryParams){
            var data = JSON.stringify(queryParams);
              
            return new Promise((resolve, reject) => {
              axios
                .post('/receipt/v1/add',data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })

          },
          fetchupdateReceipt(ctx,queryParams){
            var data = JSON.stringify(queryParams);
              
            return new Promise((resolve, reject) => {
              axios
                .put('/receipt/v1/update/'+queryParams.id,data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })

          },
          fetchPatientReciptVoucherData(ctx,queryParams){
            //var data = JSON.stringify(queryParams);
              
            return new Promise((resolve, reject) => {
              axios
                .get('/patients/vouchers/v1/byPatientsReceipt/'+queryParams.patients_id+'/'+queryParams.receipt_id)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })

          },
          fetchPatientVoucherData(ctx,queryParams){
            //var data = JSON.stringify(queryParams);
              
            return new Promise((resolve, reject) => {
              axios
                .get('/patients/vouchers/v1/byPatients/'+queryParams.patients_id)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })

          },
         
          fetchPatientData(ctx,id){
           
              
            return new Promise((resolve, reject) => {
              axios
                .get('/patients/v1/detail/'+id)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
         
    }
}