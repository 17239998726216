<template>
    <b-sidebar
        id="drop-item-form-sidebar"
        :visible="isItemFormSidebarActive"
        bg-variant="white"
        sidebar-class="sidebar-sm"
        shadow
        backdrop
        no-header
        right
        @shown="getData(patient_id)"
        
        @change="(val) => $emit('update:is-item-form-sidebar-active', val)"
    >
        <template #default="{ hide }">  
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <h5 class="mb-0">
                  คอร์สทั้งหมด
                </h5>
                <feather-icon
                class="ml-1 cursor-pointer"
                icon="XIcon"
                size="16"
                @click="hide"
                />
                

            </div>
            <div
                class="p-2"
               
            >
                <b-row class="mt-2">
                    <b-col md="4">
                        รายการ
                    </b-col>
                    <b-col md="4">
                        คงเหลือ
                    </b-col>
                    <b-col md="4">
                        จำนวนที่ใช้
                    </b-col>
                </b-row>
                <div v-for="(item,index) in coureses_num" :key="'courses-'+index">
                    <b-row class="mt-2">
                        <b-col md="4">
                            {{ item.title }}
                        </b-col>
                        <b-col md="4" class="text-center"> {{ item.product_count }}</b-col>
                        <b-col md="4">
                            <b-input-group>
                                <b-input-group-append>
                                    <b-button  :id="`tooltip-balance-target-${index}`" variant="outline-success" @click="Plus(item)">+</b-button>
                                </b-input-group-append>
                                </b-input-group>
                        </b-col>
                    </b-row>
                </div>
                <hr />
                <div v-for="(item,index) in selectedc" :key="'selected-'+index">
                    <b-row class="mt-2">
                        <b-col md="4">
                            {{ item.title }}
                        </b-col>
                        <b-col md="4" class="text-center"> <b-form-input v-model="item.value" @change="ChangeQty(item)"></b-form-input></b-col>
                        <b-col md="4">
                            <b-input-group>
                                <b-input-group-append>
                                    <b-button  :id="`tooltip-balance-target-${index}`" variant="outline-danger" @click="Minus(item)">-</b-button>
                                </b-input-group-append>
                                </b-input-group>
                        </b-col>
                    </b-row>
                </div>
                

                <hr />
                <div class="d-flex mt-2">
                    <b-button
                   
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mr-2"
                    type="submit"
                    @click="onSubmit"
                    >
                    Submit
                    </b-button>
                    <b-button
                  
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    type="button"
                    variant="outline-secondary"
                    @click="hide"
                    >
                    Cancel
                    </b-button>
                </div>
            </div>
        </template>
    </b-sidebar>
</template>
<script>
import {
  BDropdown, BDropdownItem, BSidebar, BForm, BFormGroup, BFormCheckbox, BFormInput, BFormInvalidFeedback, BButton,BFormSelect,BFormSelectOption,BFormDatepicker,BFormTimepicker,
  BInputGroup,BInputGroupAppend,BInputGroupPrepend,BRow, BCol,BBadge,BFormSpinbutton,BButtonGroup,BFormRadioGroup,BFormRadio,BTooltip
} from 'bootstrap-vue'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ref,onUnmounted } from '@vue/composition-api'

import itemStoreModule from '@/views/room/room-detail/itemStoreModule'
export default {
    components:{
        BDropdown, BDropdownItem, BSidebar, BForm, BFormGroup, BFormCheckbox, BFormInput, BFormInvalidFeedback, BButton,BFormSelect,BFormSelectOption,BFormDatepicker,BFormTimepicker,
        BInputGroup,BInputGroupAppend,BInputGroupPrepend,BRow, BCol,BBadge,BFormSpinbutton,BButtonGroup,BFormRadioGroup,BFormRadio,BTooltip

    },
    directives: {
        Ripple,
    },
    model: {
        prop: 'isItemFormSidebarActive',
        event: 'update:is-item-form-sidebar-active',
    },
    props: {
        isItemFormSidebarActive: {
            type: Boolean,
            required: true,
        
        },
        coureses_num:{
            type: Array,
            required:true
        },
        selecteds:{
            type: Array,
            required:true
        },
    
     
        patient_id:{
            type:Number,
            required: true,
        },
       

    },
    setup(props, { emit }) {
        const PATIENTS_STORE_MODULE_NAME = 'app-item';

       // const coureses = ref([]);
        const selectedc = ref([]);
        if (!store.hasModule(PATIENTS_STORE_MODULE_NAME)) store.registerModule(PATIENTS_STORE_MODULE_NAME, itemStoreModule)
        onUnmounted(() => {
            if (store.hasModule(PATIENTS_STORE_MODULE_NAME)) store.unregisterModule(PATIENTS_STORE_MODULE_NAME)
        });
        const getData = async (patient_id) =>{

            selectedc.value =[]
            
          /*let course =   await store
            .dispatch('app-item/fetchCourseItemData', {
                is_active:1,
                //branch_id:branch_id,
                patient_id:patient_id,
                balance:'0',
                order_by:'id DESC'
            
            })
            if(course.data.success){
                props.coureses = course.data.data;

            }
            console.log('getCourse',course);*/
            
        }
        const Plus = async (item)=>{
         
            const ind = props.coureses_num.findIndex(s => s.course_id === item.course_id);
            const index = selectedc.value.findIndex(s => s.course_id === item.course_id);
            
            if(item.product_count > 0){
               
                if(index > -1){ 
                    let add  = selectedc.value[index].value+1 ;
                    selectedc.value[index].value = add;
                   
                    //props.selecteds[index].balance += 1

                }else{

                    let data =  {
                        course_id : item.course_id,
                        title : item.title,
                        balance : 1,
                        value:1,
                        product_count:item.product_count,
                        course_code : item.course_code

                    }
                    console.log('item-add',data)
                    selectedc.value.push(data);

                }
                props.coureses_num[ind].product_count -= 1;

            }
            
             
            
        }
        const ChangeQty = async(item)=>{
            const ind = props.coureses_num.findIndex(s => s.course_id === item.course_id);
            props.coureses_num[ind].product_count = item.product_count -item.value;

        }
        const Minus = async (item)=>{
            const ind = props.coureses_num.findIndex(s => s.course_id === item.course_id);
            const index = props.selecteds.findIndex(s => s.course_id === item.course_id);
            props.coureses_num[ind].product_count += item.value*1;
            selectedc.value.splice(index,1);

        }
        const onSubmit = async () =>{
           // const selectedc
           
            emit('refetch-data', selectedc.value);
              
            emit('update:is-item-form-sidebar-active', false)

        }
        return{
            getData,
            onSubmit,
            ChangeQty,
          //  coureses,
            Plus,
            Minus,
            selectedc
            //selected
        }
    }
}
</script>