import axios from '@axios'
export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchData(ctx,queryParams){
            var data = JSON.stringify(queryParams);
              
            return new Promise((resolve, reject) => {
              axios
                .post('/queue/service/v1/getall',data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        fetchStaffData(ctx,queryParams){
            var data = JSON.stringify(queryParams);
              
            return new Promise((resolve, reject) => {
              axios
                .post('/queue/service/v1/getstaffs',data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },


        
        getData(ctx,id){
            
              
            return new Promise((resolve, reject) => {
              axios
                .get('/queue/service/v1/detail/'+id)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        async addData(ctx,queryParams){
            var data = queryParams;

            return new Promise((resolve, reject) => {
                axios
                  .post('/queue/service/v1/add',data)
                  .then(response => resolve(response))
                  .catch(error => reject(error))
              })

        },
        async addStaff(ctx,queryParams){
            var data = queryParams;
            return new Promise((resolve, reject) => {
                axios
                  .post('/queue/service/v1/addstaff',data)
                  .then(response => resolve(response))
                  .catch(error => reject(error))
              })

        },
        async updateData(ctx,queryParams){
            var data = queryParams;

            return new Promise((resolve, reject) => {
                axios
                  .put('/queue/service/v1/update/'+queryParams.id,data)
                  .then(response => resolve(response))
                  .catch(error => reject(error))
              })

        },
        async addCourseNum(ctx,queryParams){
            var data = queryParams.items;

            return new Promise((resolve, reject) => {
                axios
                  .put('/queue/service/v1/addcourseNum/'+queryParams.id,data)
                  .then(response => resolve(response))
                  .catch(error => reject(error))
              })

        },
        async updatePriceStaff(ctx,queryParams){
            var data = queryParams;

            return new Promise((resolve, reject) => {
                axios
                  .put('/queue/service/v1/update_price/'+queryParams.id,data)
                  .then(response => resolve(response))
                  .catch(error => reject(error))
              })

        },
        async deleteStaff(ctx,id){
            return new Promise((resolve, reject) => {
                axios
                  .delete(`/queue/service/v1/delete_staff/${id}`)
                  .then(response => resolve(response))
                  .catch(error => reject(error))
              })

        },
        async deleteData(ctx,queryParams){
            var data = queryParams;

            return new Promise((resolve, reject) => {
                axios
                  .delete(`/queue/service/v1/delete/${data}`)
                  .then(response => resolve(response))
                  .catch(error => reject(error))
              })

        },
       
    }
}