<template>
    <div>
        <queue-course-list 
        v-if="patient_id"
        :patient_id.sync="patient_id"
        :coureses.sync = "coureses"
        :selecteds.sync = "selecteds"
        :is-item-form-sidebar-active.sync="isItemFormSidebarActive"
        @refetch-data="coursesData" 
        />
        <queue-course-num-list 
        v-if="patient_id"
        :patient_id.sync="patient_id"
        :coureses_num.sync = "coureses_num"
        :selecteds.sync = "selecteds"
        :is-item-form-sidebar-active.sync="isItemNumFormSidebarActive"
        @refetch-data="coursesnumData" 
        />
        
        <queue-staff-list
        :is-staff-form-sidebar-active.sync="isStaffFormSidebarActive"
        :selecteds.sync="selecteds"
        :data="Data"
        @refetch-data="staffsData" 
        />
       
        <b-row>
            <b-col md="6">
                <b-card>
                    <b-button
                    variant="transpant"
                    class="mr-2 border-primary text-dark"
                    type="button"
                    @click="addCourse"
                   
                    >
                    รายครั้ง
                    </b-button>
                    <b-button
                    variant="transpant"
                    class="mr-2 border-primary text-dark"
                    type="button"
                    @click="addCourseNum"
                   
                    >
                    ตามจำนวน
                    </b-button>
                    <div class="mt-2 text-black">
                        <h5 class="text-black">รายการยาและอุปกรณ์ที่ใช้</h5>
                    </div>
                   
                    <div class="mt-1 p-1 border-viva text-black">
                       
                        <div v-if="items.length > 0">
                            <b-row class="mt-2" v-for="(item,index) in items" :key="'items-'+index">
                                <b-col cols="8">
                                   {{  item.title }}
                                </b-col>
                                <b-col cols="2">
                                    {{  item.stock_out }}   {{  item.unit_name }} 
                                </b-col>
                                <b-col cols="2">
                                    {{  item.group_name }}
                                    
                                </b-col>
                            </b-row>
                        </div>
                        
                        <div v-else class="text-center">ไม่พบข้อมูล!</div>
                        
                    </div>
                    
                </b-card>
            </b-col>
            <b-col md="6">
                <b-card>
                    <div class="text-black">
                        ({{ Detail.code_member }}){{ Detail.first_name }} {{ Detail.last_name }}
                    </div>
                    <div class="mt-2 text-black">
                        <h5 class="text-black">รายการคอร์สที่ใช้</h5>
                    </div>
                    <div class="mt-1 p-1 border-viva text-black">
                       
                        <div v-if="selecteds.length > 0">
                            <b-row class="mt-2" v-for="(item,index) in selecteds" :key="'selected-'+index">
                                <b-col cols="9">
                                    ({{  item.course_code }}) {{  item.title }}
                                 
                                </b-col>
                                <b-col cols="2">
                                    <b-form-spinbutton
                                        id="sb-maxmin"
                                        v-model="item.balance"
                                        min="1"
                                        v-if="Detail.status == 1"
                                        
                                        @input="changeQty(item)"
                                    />
                                    <span v-else>{{  item.balance }}</span>
                                </b-col>
                                <b-col cols="1">
                                    <b-button
                                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                                        variant="flat-danger"
                                        class="btn-icon rounded-circle"
                                        v-if="Detail.status == 1"
                                        @click="removeCourse(item)"
                                    >
                                    <feather-icon icon="Trash2Icon" />
                                    </b-button>
                                    
                                </b-col>
                            </b-row>
                            
                        </div>
                        <div v-else class="text-center">ไม่พบข้อมูล!</div>

                   

                    </div>
                </b-card>
            </b-col>

        </b-row>
        <b-row>
            <b-col md="6">
                <b-card>
                    <b-button
                    variant="transpant"
                    class="mr-2 border-primary text-dark"
                    type="button"
                    @click="addStaff('doctor')"
                    >
                    เลือกหมอ
                    </b-button>
                    <div class="mt-2 p-1 border-viva text-black">
                        <b-form
                            class="form-blue"
                        
                        >
                            
                            <b-row v-for="doctor in doctors" :key="'doctor'+doctor.id">
                                <b-col cols="8" align-self="center">
                                    {{  doctor.first_name }} {{ doctor.last_name }}
                                </b-col>
                                <b-col cols="2" >
                                
                                    <b-form-group
                                        label="ค่าแพทย์"
                                        label-for="price"
                                        >
                                        <b-form-input v-model="doctor.price" @input="updatePrice(doctor.id,$event)" />
        
                                    
                                    </b-form-group>
                                </b-col>
                                <b-col cols="2"  >
                                    <b-button
                                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                                        variant="flat-danger"
                                        class="btn-icon rounded-circle"
                                        @click="deleteconfirm(doctor.id,doctor.types)"
                                    >
                                    <feather-icon icon="Trash2Icon" />
                                    </b-button>

                                </b-col>
                            </b-row>
                        </b-form>
                    </div>
                    
                </b-card>
            </b-col>
            <b-col md="6">
                <b-card>
                    <b-button
                    variant="transpant"
                    class="mr-2 border-primary text-dark"
                    type="button"
                    @click="addStaff('assistant')"
                    >
                    เพิ่มผู้ช่วยหมอ
                    </b-button>
                    <div class="mt-2 p-1 border-viva text-black">
                        <b-form
                            class="form-blue"
                            v-if="assistants.length > 0"
                        >
                            
                            <b-row v-for="doctor in assistants" :key="'doctor'+doctor.id">
                                <b-col cols="5" align-self="center">
                                    {{  doctor.first_name }} {{ doctor.last_name }}
                                </b-col>
                                <b-col cols="3" align-self="center">
                                    {{  doctor.c_name }} ( {{ doctor.qty }} ครั้ง)
                                </b-col>
                                
                                <b-col cols="2" >
                                
                                    <b-form-group
                                        label="ค่ามือ"
                                        label-for="price"
                                        >
                                        <b-form-input v-model="doctor.price"  @input="updatePrice(doctor.id,$event)" />
        
                                    
                                    </b-form-group>
                                </b-col>
                                <b-col cols="2"  >
                                    <b-button
                                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                                        variant="flat-danger"
                                        class="btn-icon rounded-circle"
                                        @click="deleteconfirm(doctor.id,doctor.types)"
                                       
                                    >
                                    <feather-icon icon="Trash2Icon" />
                                    </b-button>

                                </b-col>
                            </b-row>
                        </b-form>
                        <div v-else class="text-center">ไม่พบข้อมูล!</div>
                    </div>
                </b-card>
            </b-col>

        </b-row>
        <b-row>
            <b-col md="12">
                <b-card  v-if="Detail.status == 1">
                    <b-button  variant="success"
                        class="mr-2 border-primary text-dark"
                        type="button"
                        @click="onSubmit"
                        v-if="selecteds.length > 0"
                    >
                        Save
                    </b-button>
                    <b-button  variant="success"
                        class="mr-2 border-primary text-dark"
                        type="button"
                        disabled
                        v-else
                    >
                        Save
                    </b-button>
                </b-card>
                <b-card  v-else>
                    <b-button  variant="success"
                        class="mr-2 border-primary text-dark"
                        type="button"
                        :to="{ name: 'queue-service'}"
                       
                    >
                        Back
                    </b-button>
                </b-card>
               
            </b-col>
        </b-row>
    </div>
</template>
<script>
import router from '@/router'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
BSidebar, BCard, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,BFormDatepicker, BRow,BCol,
BFormSpinbutton
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { slugify } from '@/@fake-db/utils'
import useQueueServiceForm from './useQueueServiceForm'
import queueServiceStoreModule from '../queueServiceStoreModule'
import { ref,onUnmounted } from '@vue/composition-api'
import OpdItemForm from '../../room/room-detail/OpdItemForm.vue'
import QueueCourseList from './QueueCourseList.vue'
import QueueCourseNumList from './QueueCourseNumList.vue'
import itemStoreModule from '@/views/room/room-detail/itemStoreModule'
import courseStoreModule from '@/views/course/courseStoreModule'
import QueueStaffList from './QueueStaffList.vue'

import receiptStoreModule from '@/views/receipt/receiptStoreModule'
export default {
    components:{
        BSidebar, BCard, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,BFormDatepicker,BRow,BCol,
        QueueCourseList,
        QueueCourseNumList,
        BFormSpinbutton,
        QueueStaffList
    },
    directives:{
        Ripple
    },
    setup(){
        const QUEUE_SERVICE_STORE_MODULE_NAME = 'queue-store';
        const PATIENTS_STORE_MODULE_NAME = 'app-item';
        const STOCK_STORE_MODULE_NAME = 'app-stock';
        const RECEIPT_STORE_MODULE_NAME = 'app-receipt'; 
        if (!store.hasModule(QUEUE_SERVICE_STORE_MODULE_NAME)) store.registerModule(QUEUE_SERVICE_STORE_MODULE_NAME, queueServiceStoreModule)
        onUnmounted(() => {
            if (store.hasModule(QUEUE_SERVICE_STORE_MODULE_NAME)) store.unregisterModule(QUEUE_SERVICE_STORE_MODULE_NAME)
        });
        if (!store.hasModule(PATIENTS_STORE_MODULE_NAME)) store.registerModule(PATIENTS_STORE_MODULE_NAME, itemStoreModule)
        onUnmounted(() => {
            if (store.hasModule(PATIENTS_STORE_MODULE_NAME)) store.unregisterModule(PATIENTS_STORE_MODULE_NAME)
        });
        if (!store.hasModule(RECEIPT_STORE_MODULE_NAME)) store.registerModule(RECEIPT_STORE_MODULE_NAME, receiptStoreModule)
        onUnmounted(() => {
            if (store.hasModule(RECEIPT_STORE_MODULE_NAME)) store.unregisterModule(RECEIPT_STORE_MODULE_NAME)
        });

       
        if (!store.hasModule(STOCK_STORE_MODULE_NAME)) store.registerModule(STOCK_STORE_MODULE_NAME, courseStoreModule)
        onUnmounted(() => {
            if (store.hasModule(STOCK_STORE_MODULE_NAME)) store.unregisterModule(STOCK_STORE_MODULE_NAME)
        });
        const {
            addCourse,
            addCourseNum,
            isItemFormSidebarActive,
            isItemNumFormSidebarActive,
            fetchOpdcardItemData,
            headertext,
            ItemData,
            Options,
            patient_id,
            fetchData,
            Detail,
            doctors,
            coursesData,
            coureses,
            coureses_num,
            selecteds,
            removeCourse,
            changeQty,
            items,
            isStaffFormSidebarActive,
            id,
            staffsData,
            assistants,
            Data,
            addStaff,
            updatePrice,
            deleteStaff,
            onSubmit,
            coursesnumData

        } = useQueueServiceForm();
        return {
            addCourse,
            isItemFormSidebarActive,
            isItemNumFormSidebarActive,
            fetchOpdcardItemData,
            headertext,
            ItemData,
            Options,
            patient_id,
            fetchData,
            Detail,
            doctors,
            coursesData,
            coureses,
            selecteds,
            removeCourse,
            changeQty,
            items,
            isStaffFormSidebarActive,
            id,
            staffsData,
            assistants,
            Data,
            coureses_num,
            addCourseNum,
            addStaff,
            updatePrice,
            deleteStaff,
            onSubmit,
            coursesnumData
        }

    },
    methods:{
        deleteconfirm(id,types){
            this.$bvModal
                .msgBoxConfirm('Please confirm that you want to delete Staff ID.'+id, {
                title: 'Please Confirm',
                size: 'sm',
                okVariant: 'danger',
                okTitle: 'Yes',
                cancelTitle: 'No',
                cancelVariant: 'outline-secondary',
                hideHeaderClose: false,
                centered: true,
                })
                .then(value => {
                if(value === true){
                    this.deleteStaff(id, types)
                }
            
                //this.deleteData(id)
                })

        },

    }
    
}
</script>